.helpText {
  font-style: italic;
}
.errorText {
  color: red;
}
.inputContainer {
  .labelName {
    width: 150px;
    text-align: end;
    font-size: 15px;
  }
}
