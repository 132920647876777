.resetBtn {
  margin-left: 10px;
  margin-right: 20px;
  width: 100px;
  border: 1px black solid;
}

.saveBtn {
  margin-right: 20px;
}

.dataContainer {
  margin-bottom: 20px;
}

.inputDataContainer {
  @extend .dataContainer;
  margin-left: 20px;
}

.calculatedDataContainer {
  @extend .dataContainer;
  margin-left: 30px;
}

.btnContainer {
  margin: 0px 20px;
  padding-bottom: 20px;
}
