.headerContainer {
  height: 50px;
  display: flex;
  align-items: center;
  background-color: #e2fdfd;
  padding-left: 40px;
  padding-right: 20px;

  .headerTitle {
    font-size: 24px;
    color: white;
    font-weight: bold;
    cursor: pointer;
  }
  .menuList {
    font-size: 18px;
    .link {
      color: var(--color-darkBlue);
      text-decoration: none;
      margin: 0px 20px;
      padding-left: 5px;
      padding-right: 5px;
      font-weight: 600;
    }
    .linkActive {
      @extend .link;
      font-weight: bold;
      border-bottom: 2px solid var(--color-darkBlue);
    }
  }

  .menuContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 20px;
    flex: 1;
  }
}

.linkMenu {
  font-weight: 600;
}
