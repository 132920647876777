.warningText {
  border: 2px solid #ff9900;
  padding: 3px;
  font-size: 16px;
  border-radius: 5px;
  max-width: 505px;
  background-color: #ffefc2;
  display: flex;
  flex-direction: row;
  .warningIcon {
    color: #ff9900;
    font-size: 22px;
    vertical-align: middle;
    padding: 0px 10px;
    margin-bottom: 3px;
  }
}
