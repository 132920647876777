.individualDetail {
  display: flex;
  padding: 5px;
  font-size: 16px;
  .label {
    width: 120px;
    text-align: right;
    color: #000000;
  }
  .value {
    margin-left: 15px;
    color: #333333;
    width: 250px;
  }
}
