.select {
  border: 0.5px solid var(--color-border) !important;
  border-radius: 5px;

  :hover {
    border-color: var(--color-primary) !important;
  }
}

.selectError {
  border: 0.5px solid var(--color-error) !important;
  border-radius: 5px;
}
