:root {
  --color-lightBlue: #67b8b7;
  --color-darkBlue: #0d313e;
}

// variable to store color code used as background in overall app
$lightBlueColor: var(--color-lightBlue);
$darkBlueColor: var(--color-darkBlue);

body {
  display: block;
  margin: 0px !important;
  background-color: #fffcfc !important;
}

@mixin borderRadius4() {
  border-radius: 4px !important;
}

.ant-input {
  @include borderRadius4();
}

.ant-input-number {
  @include borderRadius4();
}

.ant-picker {
  @include borderRadius4();
}

.ant-select {
  @include borderRadius4();
}

.ant-btn {
  border-radius: 6px !important;
}

.helpButton {
  text-align: center !important;
  color: $darkBlueColor !important;
  border-color: $darkBlueColor !important;
  border-radius: 15px !important;
  font-weight: 500 !important;
  font-size: 13 !important;
  position: fixed !important;
  bottom: 30px !important;
  left: 2% !important;
}

.buttonColorRed {
  text-align: center !important;
  background: $lightBlueColor !important;
  color: white !important;
  border-color: $lightBlueColor !important;
}
.buttonColorRed:hover {
  background: $darkBlueColor !important;
  color: white !important;
}
.buttonColorRed:active {
  background: #65b2d6 !important;
  color: white !important;
  border-color: $darkBlueColor !important;
}
.buttonColorRed:focus {
  background: $lightBlueColor !important;
  color: white !important;
  border-color: #2b78e4 !important;
}
.buttonColorRed:visited {
  background: $lightBlueColor !important;
  color: white !important;
  border-color: #2b78e4 !important;
}
.buttonColorRed:focus-within {
  background: $lightBlueColor !important;
  color: white !important;
  border-color: #2b78e4 !important;
}

.requiredField:before {
  content: '*';
  color: red;
  margin-right: 3px;
}

.requiredFieldAfter:after {
  content: '*';
  color: red;
}

.selectBoxContainer {
  .selectBoxLabel {
    font-size: 18px;
    color: #000000;
  }
  .selectBox {
    margin-left: 15px;
    border: 1px solid black;
    border-radius: 5px;
    .ant-select-selector {
      border-radius: 5px !important;
    }
  }
}

.errorMessage {
  color: red;
}

// ---------------------------------------------------------FarmLayout----------------------------------------------------------------

.tabContainer {
  .react-tabs__tab--selected {
    background: #fff !important;
    border-radius: 0px;
    border-bottom: 1px solid #ffffff !important;
  }
  .react-tabs__tab {
    background: #d3d3d3;
    border-color: black;
    border-radius: 0px;
    border-bottom: 1px solid black;
  }
}

// --------------------------------------------------------------------------------------------------------------------

.drawerContainer {
  .ant-drawer-header {
    border-bottom: 2px solid #b3b3b3;
  }
  .ant-drawer-body {
    padding: 0;
  }
}

.loadingIndicator {
  display: flex;
  align-items: center;
  justify-content: center;
}

.ant-spin-dot-item {
  background-color: $lightBlueColor !important;
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: $lightBlueColor !important;
  border-color: $lightBlueColor !important;
}

// by calf collapse style
.ant-collapse-header {
  padding-top: 5px !important;
  padding-bottom: 5px !important;
}

// selected radio button border style
.ant-radio-checked .ant-radio-inner {
  border-color: $lightBlueColor !important;
}

// selected radio button inner color style
.ant-radio-inner::after {
  background-color: $lightBlueColor !important;
}

.ant-checkbox-indeterminate .ant-checkbox-inner::after {
  background-color: $lightBlueColor !important;
}
